<!--操作票管理-->
<template>
    <div class="ui-layout-container ui-height-100">
    <div v-if="showVisible" class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="workflow-list__header">
            <div class="workflow-list__navbar">
                <el-menu :default-active="type" class="el-menu-bm" mode="horizontal" @select="handleSelect">
                    <el-menu-item index="all">所有业务</el-menu-item>
                    <el-menu-item index="selfStart">我发起的</el-menu-item>
                    <el-menu-item index="pending">
                        待我处理
                        <!-- <span class="workflow-list__notice-count">10</span> -->
                    </el-menu-item>
                    <el-menu-item index="processed">已处理的</el-menu-item>
                    <el-menu-item index="finished">已归档</el-menu-item>
                </el-menu>
            </div>
        </div>
        <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div v-if="canAdd" class="ui-layout-content--full ui-form-actions" style="margin: 20px 20px 0px 20px">
            <el-button @click="addOrUpdateHandle" type="primary" style="border-radius: 4px">+ 新增</el-button>
        </div>
        <div class="ui-layout-content--full ui-layout-flex--1" style="margin: 0px 20px 20px 20px">
            <ex-search-table-pagination
                style="height: 100%"
                ref="searchTable"
                :fetch="fetchData"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
                pageSizeKey="pageSize"
                pageIndexKey="pageNumber"
                listField="items"
                totalField="totalItemCount"
                :params="{definitionCode}"
            >
                <template slot="append">
                    <el-table-column
                        align="center"
                        label="操作"
                        type="action"
                        width="200"
                        fixed="right"
                    >
                        <template slot-scope="scope">
                            <!--v-if="scope.row.status !== 'finished'"-->
                                <el-button v-if="type === 'pending'" @click="conductInfo(scope.row)">处理</el-button>
                                <el-button v-if="type != 'pending'" @click="details(scope.row)">详情</el-button>
                                <el-button v-if="deletebutton(scope.row)" @click="deleteForm(scope.row)">删除</el-button>

                            <!--<el-tooltip content="删除" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_delete"
                                    @click="deleteHandle(scope.row.id)"
                                    type="text"
                                ></div>
                            </el-tooltip>-->
                        </template>
                    </el-table-column>
                </template>
            </ex-search-table-pagination>
        </div>
    </div>
    </div>
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @closeForm="closeForm"></add-or-update>
    <Details v-if="detailsVisible" ref="details" @closeForm="closeForm"></Details>
    <info v-if="infoVisible" ref="info" @closeForm="closeForm"></info>
    </div>
</template>

<script>
import AddOrUpdate from '../operationOrder/operationOrder-add-or-update';
import Info from '../operationOrder/review';
import Details from '../operationOrder/operationOrderDetails';
export default {
    data() {
        return {
            detailsVisible: false,
            infoVisible: false,
            showVisible: true,
            addOrUpdateVisible: false,
            totalItemCount: null,
            items: [],
            definitionCode: 'elec_operation_ticket',
            canAdd: false, //是否有权限新增
            type: 'pending',
            reviewForm: {
                rejectNext: '', //驳回至哪一步
                reviewNote: '',
                reviewStatus: 0,
                reviewParam: 0,
                reviewBy: '',
            },
        };
    },
    components: {
        Details,
        Info,
        AddOrUpdate,
    },
    computed: {
        columns() {
            return [
                {
                    prop: 'number',
                    label: '操作票编号',
                    formatter: (row, column, cellValue) => {
                        if (row.currentRecord?.data?.data) {
                            let a = row.currentRecord.data.data[row.currentRecord.data.data.length - 1];
                            return a.number;
                        }
                    },
                },
                {
                    prop: 'name',
                    label: '操作票名称',
                    formatter: (row, column, cellValue) => {
                        if (row.currentRecord?.data?.data) {
                            let a = row.currentRecord.data.data[row.currentRecord.data.data.length - 1];
                            return a.name + '现场电气操作票';
                        }
                    },
                },
                {
                    prop: 'state',
                    label: '状态',
                    formatter: (row, column, cellValue) => {
                        if (row.nextNode?.name) {
                            return row.nextNode.name;
                        }
                        if (!row.nextNode && row.status === 'halted') {
                            return '已作废';
                        }
                        return '已执行';
                    },
                },
                {
                    prop: 'startTime',
                    label: '发起时间',
                    formatter: (row, column, cellValue) => {
                        if (row) {
                            return row.startTime;
                        }
                    },
                },
                {
                    prop: 'endTime',
                    label: '完成时间',
                    formatter: (row, column, cellValue) => {
                        if (row) {
                            return row.endTime;
                        }
                    },
                },
            ];
        },
        formOptions() {
            return {
                formData: {
                    number: '', //最后一条数据的编号
                    year: '', //年份
                },
                forms: [
                    {
                        prop: 'state',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '状态',
                        options: [
                            {code: 'elec_operation_ticket_one', value: '填写申请'},
                            {code: 'elec_operation_ticket_two', value: '操作人审核'},
                            {code: 'elec_operation_ticket_three', value: '监护人审核'},
                            {code: 'elec_operation_ticket_four', value: '值班负责人审核'},
                            {code: 'elec_operation_ticket_five', value: '回执'},
                        ],
                        valueKey: 'code',
                        labelKey: 'value',
                    },
                    {
                        prop: 'number',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '操作票编号',
                    },
                ],
            };
        },
    },
    created() {
        this.setAddBtn();
    },
    methods: {
        //删除按钮权限
        deletebutton(row) {
            if (this.type === 'pending' && row && row.nextNode?.code === 'elec_operation_ticket_one') {
                let createUser = row.extraProps.filter((item) => item.code === 'createUser');
                let curUser = this.$store.state.userInfo.username;
                if (createUser && createUser.length > 0 && createUser[0].value === curUser) {
                    return true;
                }
            }
            return false;
        },
        //删除
        deleteForm(row) {
            this.$confirm(`确定删除选中的数据`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                if (this.deletebutton(row)) {
                    let ids = [row.id];
                    this.$client.workflowDelete(ids).then((res) => {
                        if (res.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 2000,
                            });
                        }
                        this.$refs.searchTable.searchHandler();
                    });
                } else {
                    this.$message({
                        message: '你没有权限删除',
                        type: 'error',
                    });
                    return;
                }
            });
        },
        //详情
        details(item) {
            this.showVisible = false;
            this.detailsVisible = true;
            this.$nextTick(() => {
                this.$refs.details.init(item);
            });
        },
        setAddBtn() {
            let user = this.$store.state.userInfo;
            if (user && user.roleCodeList && user.roleCodeList.indexOf('CZPTXR') > -1) {
                this.canAdd = true;
            }
        },
        handleSelect(key) {
            this.type = key;
            this.$nextTick(() => {
                this.$refs.searchTable.searchHandler();
            });
        },
        //处理
        conductInfo(item) {
            this.showVisible = false;
            this.infoVisible = true;
            this.$nextTick(() => {
                this.$refs.info.init(item);
            });
        },
        //关闭页面
        closeForm(type) {
            this.showVisible = true;
            this.infoVisible = false;
            this.addOrUpdateVisible = false;
            this.detailsVisible = false;
        },
        // 新增
        addOrUpdateHandle(item) {
            this.showVisible = false;
            this.addOrUpdateVisible = true;
            let a = '';
            if (this.number) {
                a = (this.number).toString().substr(5, 2);
            } else {
                a = 0;
            }
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(item, a);
            });
        },
        fetchData(params) {
            if (!params.extraProp) {
                for (let item in this.extraProp) {
                    params[`extraProp.${item}`] = this.extraProp[item];
                }
            }
            params.type = this.type;
            if (this.type === 'finished') {
                params.type = 'all';
                params.status = this.type;
            }
            this.fetchNumber();
            return new Promise((resolve, reject) => {
                this.$client.getOperationOrderList(params).then(data => {
                    let ids = [];
                    let result = data.data.items.map(e => {
                        //e.currentRecordId = e.records.sort((a, b) => b.startTime > a.startTime ? 1 : -1)[0].id;
                        e.currentRecordId = e.records[0].id;
                        e.records.forEach(r => {
                            ids.push(r.id);
                        });
                        //ids.push(e.currentRecordId);
                        return e;
                    });
                    this.$client.getQueryRecords({
                        ids
                    }).then(resp => {
                        result = result.map(e => {
                            resp.data.items.sort();
                            e.currentRecord = resp.data.items.filter(i => i.id === e.currentRecordId)[0];
                            e.records.map(i => {
                                const record = resp.data.items.filter(r => r.id === i.id)[0];
                                i.data = record ? record.data : null;
                                return i;
                            });
                            return e;
                        });
                        let tableData = {...data.data};
                        tableData.items = result;
                        resolve({data: tableData});
                    });
                });
            });
        },
        fetchNumber() {
            this.$client.getOperationOrderList({
                definitionCode: this.definitionCode,
                pageSize: 1
            }).then(resp => {
                if (resp.code && resp.data && resp.data.items.length > 0) {
                    let result = resp.data.items[0].extraProps.filter(e => e.code === 'number');
                    if (result.length > 0) {
                        this.number = result[0].value;
                    }
                }
            });
        }
    },
};
</script>
<style lang="less" scoped>
    @import '~@/assets/style/variables.less';

    .workflow-list {
        &__navbar {
            border-bottom: 1px solid @bg-border;
            padding-top: 10px;
            /deep/ .el-menu {
                background-color: transparent;
            }
            /deep/ .el-menu.el-menu--horizontal {
                height: 30px;
                border: none;
                margin-right: 0;
                margin-bottom: 0;
            }
            /deep/ .el-menu--horizontal > .el-menu-item {
                height: 35px;
                line-height: 35px;
            }
            /deep/ .el-header {
                padding: 0;
            }
            /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
            /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
            /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
                background-color: lighten(@bg-dark, 22%);
            }
            /deep/ .el-menu--horizontal > .el-menu-item.is-active {
                border-color: #ededfd;
            }
        }
    }
</style>
