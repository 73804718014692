<template>
    <div>
    <div class="form-table">
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>{{ '操作票' }}</span>
                <el-button @click="exportFrom()" type="primary" style="float: right; margin-right: 28%"
                >导出</el-button
                >
            </div>
            <div v-for="temp in dynamicValidateForm">
                <div class="ulbox overhaul">
                    <table cellspacing="0px">
                    <tr>
                        <td colspan="6" style="border-bottom: 0px">
                            <p style="text-align: center; font-weight: 900">
                                {{ temp.name }} 现场电气操作票
                            </p>
                        </td>
                    </tr>
                        <tr>
                            <td colspan="4" style="border-top: 0px; border-right: 0px"></td>
                            <td colspan="1" style="border-top: 0px; border-right: 0px; border-left: 0px; text-align: right">编号：</td>
                            <td colspan="1" style="border-top: 0px; border-left: 0px; text-align: left"> {{ temp.number }}</td>
                        </tr>
                    <tr>
                        <td colspan="1">类型</td>
                        <td colspan="5">
                            <el-checkbox v-if="temp.type === '1'" v-model="checked" disabled>根据调度令进行的操作</el-checkbox>
                            <el-checkbox v-if="temp.type === '2'" v-model="checked" disabled>根据本单位任务进行的操作</el-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1">发令单位</td>
                        <td colspan="2"> {{ temp.startingUnit }} </td>
                        <td colspan="1">发令人</td>
                        <td colspan="2"> {{ temp.startingBy }} </td>
                    </tr>
                    <tr>
                        <td colspan="1">受令人</td>
                        <td colspan="2"> {{ temp.receivedBy }} </td>
                        <td colspan="1">受令时间</td>
                        <td colspan="2"> {{ temp.receivedTime }} </td>
                    </tr>
                     <tr>
                         <td colspan="1">操作开始时间</td>
                         <td colspan="2"> {{ temp.beginTime }} </td>
                         <td colspan="1">操作结束时间</td>
                         <td colspan="2"> {{ temp.endTime }} </td>
                     </tr>
                     <tr>
                       <td colspan="1">操作任务</td>
                       <td colspan="5"> {{ temp.operatingDuty }} </td>
                     </tr>
                     <tr>
                         <td colspan="1">顺序</td>
                         <td colspan="4">操作项目</td>
                         <td colspan="1">操作</td>
                     </tr>
                      <tr v-for="item in temp.operatingSteps">
                          <td colspan="1"> {{ item.sort }} </td>
                          <td colspan="4"> {{ item.stepContent }} </td>
                          <td colspan="1"> {{ item.state }} </td>
                      </tr>
                      <tr>
                          <td colspan="1">备注</td>
                          <td colspan="5"> {{ temp.remarks }} </td>
                      </tr>
                       <tr>
                           <td>操作人</td>
                           <td> {{ temp.operatorBy }} </td>
                           <td>监护人</td>
                           <td> {{ temp.guardian }} </td>
                           <td>值班负责人</td>
                           <td> {{ temp.principal }} </td>
                       </tr>
                        <tr>
                            <td colspan="1">回执单</td>
                            <td colspan="6">
                                <div
                                    v-for="item in temp.files"
                                    key="item"
                                    @click="downloadfile(item)"
                                    style="cursor: pointer; color: #0066ff"
                                >
                                    {{ item.name }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </el-card>
        <record-history v-if="showRecordHistory" :instance="currentData"/>
    </div>
    <div class="submit-btn-group button-fixed">
        <el-button @click="closeForm">关闭</el-button>
    </div>
    </div>
</template>

<script>
import {previewPdf, fileDownload} from '@/utils';
import RecordHistory from './RecordHistory';
export default {
    name: 'operationOrderInfo',
    components: {
        RecordHistory
    },
    data() {
        return {
            showRecordHistory: false,
            currentData: {},
            checked: true,
            dynamicValidateForm: [{
                id: null,
                name: '', //工作票名称
                type: '', //工作票类型
                startingUnit: '', //发令单位
                number: '', //工作票编号
                startingBy: '', //发令人
                receivedBy: '', //受令人
                receivedTime: '', //受令时间
                beginTime: '', //操作开始时间
                endTime: '', //操作结束时间
                operatingDuty: '', //操作任务
                remarks: '', //备注
                operatorBy: '', //操作人
                guardian: '', //监护人
                principal: '', //值班负责人
                files: [], //上传文件
                operatingSteps: [],
            }],
        };
    },
    created() {
    },
    methods: {
        closeForm() {
            this.$emit('closeForm');
        },
        //导出表单
        exportFrom() {
            let data = this.dynamicValidateForm;
            fileDownload('/business/operationOrders/download', {method: 'POST', data});
        },
        //下载附件
        downloadfile(item) {
            fileDownload('/files/' + item.id + '?token=' + this.$store.state.token);
        },
        init(row) {
            this.currentData = row;
            this.showRecordHistory = true;
            if (row.id) {
                this.dynamicValidateForm = row.currentRecord.data.data;
            }
        },
    },
};
</script>
<style lang="less" scoped>
    .notice_title {
        margin-left: 22%;
    }
    table {
        text-align: center;
        margin-left: 20%;
        width: 60%;
        padding: 0px 15px 20px 20px;
        td {
            height: 30px;
        }
    }

    table td {
        border: 0.1px #c0c0c0 solid;
        padding-left: 5px;
    }
    .overhaul table td {
        width: 10%;
    }

    .receive table td {
        width: 12.5%;
    }

    .line-height-200 {
        line-height: 200%;
    }
    .form-table {
        height: calc(100vh - 170px);
        margin-bottom: 10px;
        overflow-y: scroll;
    }
    .button-fixed {
        margin: 0 !important;
    }
    .submit-btn-group {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 60px;
    }
</style>
