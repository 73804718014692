<template>
    <div>
    <div class="form-table">
        <operation-order v-if="showOperationOrder" ref="ref_operationOrder" @closeForm="closeForm"></operation-order>
        <!--通用审核组件,默认不显示-->
        <div v-if="showReviewForm">
            <el-card class="box-card" style="margin: 0px 50px 50px 50px">
                <div slot="header" class="clearfix">
                    <span>审核情况</span>
                </div>
                <el-form
                    label-position="top"
                    :model="reviewForm"
                    ref="reviewForm"
                    @keyup.enter.native="reviewFormSubmit()"
                    label-width="120px"
                >
                    <el-form-item label="审核意见" prop="reviewNote">
                        <el-input
                            type="textarea"
                            v-model="reviewForm.reviewNote"
                            placeholder="审核意见"
                            :row="5"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-dialog title="选择驳回步骤" :visible.sync="dialogVisible" width="30%">
                <el-table
                    :data="rejectNodesList"
                    border
                    highlight-current-row
                    @current-change="handleCurrentChange"
                    style="width: 100%"
                    height="250"
                >
                    <el-table-column align="center" fixed prop="name" :show-overflow-tooltip="true" label="步骤名称">
                    </el-table-column>
                </el-table>
                <div style="text-align:center;margin-top:10px;">
                    <el-button type="primary" @click="rejectSubmit">驳回</el-button>
                </div>
            </el-dialog>
        </div>
        <record-history v-if="showRecordHistory" :instance="data" />
    </div>
        <div class="submit-btn-group button-fixed">
            <el-button @click="closeForm">关闭</el-button>
            <el-button v-if="this.data.nextNode" type="danger" @click="showRejectDialog">驳回</el-button>
            <el-button v-if="this.data.nextNode" type="primary" @click="reviewFormSubmit(1)">通过</el-button>
            <el-button v-if="ratifyCancellationShow()" @click="ratifyCancellation()">作废</el-button>

        </div>
    </div>
</template>

<script>
/**
 * 通用审核组件
 */
import OperationOrder from './operationOrderInfo';
import RecordHistory from './RecordHistory';
export default {
    components: {
        OperationOrder,
        RecordHistory
    },
    data() {
        return {
            showOperationOrder: false,
            showElectricPlanInfo: false,
            showFlawTaskAcceptInfo: false,
            showFlawTaskCompletionInfo: false,
            showReviewForm: false,
            showRecordHistory: false,
            workflowTaskId: 0,
            reviewForm: {
                rejectNext: '', //驳回至哪一步
                reviewNote: '',
                reviewStatus: 0,
                reviewParam: 0,
                reviewBy: '',
            },
            rejectNodesList: [],
            showRejectBox: true,
            rejectCallback: null,
            dialogVisible: false,
            data: {},
            records: [], //审核记录
            currentRow: null
        };
    },
    methods: {
        ratifyCancellation() {
            this.$confirm('确定同意作废该项工作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$client
                    .workflowInstanceProcess({
                        definitionCode: 'elec_operation_ticket',
                        instanceId: this.data.id,
                        nodeCode: this.data.nextNode.code,
                        operationType: 'halt',
                        data: {data: this.data.currentRecord.data?.data, reviewForm: this.reviewForm},
                    })
                    .then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                        });
                        this.closeForm(2);
                    });
            });
        },
        //作废按钮权限
        ratifyCancellationShow() {
            if (this.data.nextNode?.code === 'elec_operation_ticket_five') {
                return true;
            }
            return false;
        },
        init(item) {
            this.data = item;
            this.rejectNodesList = item.rejectNodes;
            this.workflowTaskId = item.id;
            this.extraProps = item.extraProps;
            this.records = item.records;
            this.showOperationOrder = true;
            this.showReviewForm = true;
            this.showRecordHistory = true;
            this.setRejectNode();
            this.$nextTick(() => {
                this.$refs.ref_operationOrder.init(item);
            });
        },
        //设置rejectNode的处理人
        setRejectNode() {
            if (!this.rejectNodesList || this.rejectNodesList.length < 1) {
                return;
            }
            let records = this.records;
            this.rejectNodesList.forEach((reject) => {
                //发起环节
                if (reject.code === 'elec_operation_ticket_one') {
                    this.extraProps.forEach((r) => {
                        if (r.code === 'createUser') {
                            reject.handler = r.value;
                        }
                    });
                }
                //审批环节
                if (records && records.length > 0) {
                    records.forEach((r) => {
                        if (reject.id === r.node.id) {
                            reject.handler = r.handler;
                        }
                    });
                }
            });
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        },
        showRejectDialog() {
            this.dialogVisible = true;
        },
        rejectSubmit() {
            if (this.currentRow == null) {
                this.$message({
                    message: '请选择驳回步骤',
                    type: 'error'
                });
                return;
            }
            this.reviewForm.rejectNext = this.currentRow.name;
            this.$confirm(`确定驳回到${this.currentRow.name}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client
                    .workflowInstanceProcess({
                        definitionCode: 'elec_operation_ticket',
                        instanceId: this.data.id,
                        nodeCode: this.data.nextNode.code,
                        operationType: 'reject',
                        rejectNodeCode: this.currentRow.code,
                        data: {data: this.data.currentRecord.data?.data, reviewForm: this.reviewForm},
                        user: this.$store.state.userInfo.username,
                        roles: this.$store.state.userRole,
                        nextUsers: [this.currentRow.handler]
                    })
                    .then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        });
                        this.closeForm(2);
                    });
            });
        },
        reviewFormSubmit(reviewStatus) {
            this.reviewForm.reviewStatus = reviewStatus;
            this.$nextTick(() => {
                this.$refs.ref_operationOrder.dataFormSubmit(this.reviewForm);
            });
        },
        /*this.$client
                .workflowInstanceProcess({
                    definitionCode: 'elec_operation_ticket',
                    instanceId: this.data.id,
                    nodeCode: this.data.nextNode.code,
                    operationType: 'saveAndNext',
                    data: this.reviewForm,
                    user: this.$store.state.userInfo.username,
                    roles: this.$store.state.userRole,
                })
                .then(() => {
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                    });
                    this.closeForm(2);
                });*/
        closeForm(type) {
            this.$emit('closeForm', type);
            this.$root.$emit('operationOrderRefresh');
        },
        clickStep(index) {
            this.$emit('clickStep', index);
        }
    }
};
</script>
<style lang="less" scoped>
    .form-table {
        height: calc(100vh - 170px);
        margin-bottom: 10px;
        overflow-y: scroll;
    }
    .button-fixed {
        margin: 0 !important;
    }
    .submit-btn-group {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 60px;
    }
</style>
