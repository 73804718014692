<template>
    <div style="padding: 50px 50px 50px 50px">
        <el-card>
        <el-form style="margin-top: 30px" v-for="(dataForm, index)  in dynamicValidateForm" :key="index" :model="dataForm" :rules="dataRule" :ref="dynamicValidateForm"
                 label-width="120px" label-position="top">
            <div style="text-align: center">
            <span style="font-size: 18px;">
                <input class="factory_name" v-model="dataForm.name" :disabled="getDisable(DISABLE_VALUE.FILL)"/> 现场电气操作票
             </span>
            </div>
            <div style="text-align: right; margin-top: -40px">
                <span>
                <el-button type="primary" @click="exportFrom">导出</el-button>
                </span>
            </div>
            <el-form-item label="编号:">
                <el-input style=" width: 220px;" v-model="dataForm.number" placeholder="请输入编号" :disabled="getDisable(DISABLE_VALUE.FILL)"></el-input>
            </el-form-item>
            <el-form-item label="类型:">
                <el-radio-group v-model="dataForm.type" :disabled="getDisable(DISABLE_VALUE.FILL)">
                    <el-radio  label="1">根据调度令进行的操作</el-radio>
                    <el-radio  label="2">根据本单位任务进行的操作</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-row :gutter="24">
                <el-col :span="6">
            <el-form-item label="发令单位：">
                <el-input style=" width: 220px;" :disabled="getDisable(DISABLE_VALUE.FILL)" v-model="dataForm.startingUnit" placeholder="请输入发令单位"></el-input>
            </el-form-item>
                </el-col>
                <el-col :span="6">
            <el-form-item label="发令人：">
                <el-input style=" width: 220px;" :disabled="getDisable(DISABLE_VALUE.FILL)" v-model="dataForm.startingBy" placeholder="请输入发令人"></el-input>
            </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="6">
                    <el-form-item label="受令人：">
                        <el-input style=" width: 220px;" :disabled="getDisable(DISABLE_VALUE.FILL)" v-model="dataForm.receivedBy" placeholder="受令人"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="受令时间：">
                        <el-date-picker
                            :disabled="getDisable(DISABLE_VALUE.FILL)"
                            v-model="dataForm.receivedTime"
                            type="datetime"
                            placeholder="选择日期"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="6">
                    <el-form-item label="操作开始时间：">
                        <el-date-picker
                            :disabled="getDisable(DISABLE_VALUE.FILL)"
                            v-model="dataForm.beginTime"
                            type="datetime"
                            placeholder="选择日期"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="操作结束时间：">
                        <el-date-picker
                            :disabled="getDisable(DISABLE_VALUE.FILL)"
                            v-model="dataForm.endTime"
                            type="datetime"
                            placeholder="选择日期"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="操作任务：">
                <el-input
                    :disabled="getDisable(DISABLE_VALUE.FILL)"
                    v-model="dataForm.operatingDuty"
                    type="textarea"
                    rows="3"
                    placeholder="请输入操作任务"
                    minlength="2"
                    maxlength="100"
                    show-word-limit
                >
                </el-input>
            </el-form-item>
            <el-table
                :data="dataForm.operatingSteps"
                style="width: 100%"
                border>
                <el-table-column
                    fixed
                    prop="sort"
                    label="序号"
                    width="100">
                    <template slot-scope="scope">
                        <el-input :disabled="getDisable(DISABLE_VALUE.FILL)" v-model="scope.row.sort"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                prop="stepContent"
                label="操作项目">
                <template slot-scope="scope">
                    <el-input :disabled="getDisable(DISABLE_VALUE.FILL)" v-model="scope.row.stepContent"/>
                </template>
            </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.state" clearable placeholder="请选择操作结果" :disabled="getDisable(DISABLE_VALUE.FILES)">
                            <el-option
                                v-for="item in isPerform"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </template>>
                </el-table-column>
            </el-table>
            <el-form-item label="备注：">
                <el-input
                    :disabled="getDisable(DISABLE_VALUE.FILL)"
                    v-model="dataForm.remarks"
                    type="textarea"
                    rows="3"
                    placeholder="备注"
                    maxlength="400"
                    show-word-limit
                >
                </el-input>
            </el-form-item>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="操作人：">
                        <el-select :disabled="getDisable(DISABLE_VALUE.FILL)" v-model="dataForm.operatorBy" clearable placeholder="请选择操作人">
                            <el-option
                                v-for="item in roleList"
                                :key="item.userId"
                                :label="item.realName"
                                :value="item.realName"
                                :disabled="item.disabled">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="监护人：">
                        <el-select :disabled="getDisable(DISABLE_VALUE.FILL)" v-model="dataForm.guardian" clearable placeholder="请选择监护人">
                            <el-option
                                v-for="item in roleList"
                                :key="item.userId"
                                :label="item.realName"
                                :value="item.realName"
                                :disabled="item.disabled">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="值班负责人：">
                        <el-select :disabled="getDisable(DISABLE_VALUE.FILL)" v-model="dataForm.principal" clearable placeholder="请选择值班负责人">
                            <el-option
                                v-for="item in roleList"
                                :key="item.userId"
                                :label="item.realName"
                                :value="item.realName"
                                :disabled="item.disabled">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="change-line">
                <el-form-item label="上传操作票回执：">
                <ex-uploader :disabled="getDisable(DISABLE_VALUE.FILES)" v-model="dataForm.files"></ex-uploader>
                </el-form-item>
            </div>
        </el-form>
      <!--  <div style="text-align: center">
      <el-button @click="closeForm">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit('saveAndNext')">下一环节</el-button>
      <el-button type="primary" @click="dataFormSubmit('reject')">驳回</el-button>
      <el-button></el-button>
        </div>-->
        </el-card>
    </div>
</template>

<script>
import {cloneDeep} from 'lodash';
import {fileDownload} from '@/utils';
export default {
    props: {
        dictData: null,
    },
    data() {
        return {
            DISABLE_VALUE: {
                FILL: 'fill', //填写基础信息
                //操作人审核
                //监护人审核
                //值班负责人审核
                AUDIT: 'audit', //审核
                FILES: 'files', //归档
            },
            isPerform: [{value: 1, label: '√'}, {value: 0, label: '×'}],
            roleListCZP: [],
            roleList: [],
            nextRoles: [], //下一步操作角色
            nextUsers: [], //下一步处理人
            curCode: '', //当前环节CODE
            count: null, //工作票张数
            roleInfo: [], //用户信息
            userName: '', //当前登录用户账号
            nextNodeCode: '', // 下一步流程CODE
            instanceId: '', //当前流程ID
            jumpNodeId: '', //上一步流程ID
            reviewForm: {
                rejectNext: '', //驳回至哪一步
                reviewNote: '',
                reviewStatus: 0,
                reviewParam: 0,
                reviewBy: '',
            },
            dynamicValidateForm: [{
                id: null,
                name: '', //工作票名称
                type: '', //工作票类型
                startingUnit: '云南滇中新区配售电有限公司', //发令单位
                number: '', //工作票编号
                startingBy: '赵仪轩', //发令人
                receivedBy: '', //受令人
                receivedTime: '', //受令时间
                beginTime: '', //操作开始时间
                endTime: '', //操作结束时间
                operatingDuty: '', //操作任务
                remarks: '', //备注
                operatorBy: '', //操作人
                guardian: '', //监护人
                principal: '', //值班负责人
                files: [],
                operatingSteps: Array.from({length: 12}, x => ({
                    sort: null,
                    stepContent: '',
                    state: '',
                }))
            }],
            dataRule: {},
        };
    },
    created() {
        this.getRoleListCZP();
    },
    methods: {
        //获取检修相关角色
        getRoleListCZP() {
            let unitType = 'CZP';
            this.$client.queryOverhaulRoleList(unitType).then((data) => {
                this.roleListCZP = data.data;
            });
        },
        //获取下一环节角色
        setNextUserAndRole() {
            if (!this.roleListCZP) {
                return;
            }
            this.nextRoles = [];
            this.nextUsers = [];
            let roles = this.roleListCZP;
            switch (this.nextNodeCode) {
                case 'elec_operation_ticket_one':
                    //待操作人审核
                    roles.forEach((item) => {
                        if (item.roleCode === 'CZPCZR') {
                            this.nextRoles.push(item.roleId);
                        }
                    });
                    break;
                case 'elec_operation_ticket_two':
                    //待操作人审核
                    roles.forEach((item) => {
                        if (item.roleCode === 'CZPJHR') {
                            this.nextRoles.push(item.roleId);
                        }
                    });
                    break;
                case 'elec_operation_ticket_three':
                    //待监护人审核
                    roles.forEach((item) => {
                        if (item.roleCode === 'CZPZBR') {
                            this.nextRoles.push(item.roleId);
                        }
                    });
                    break;
                case 'elec_operation_ticket_four':
                    //待值班负责人审核
                    roles.forEach((item) => {
                        if (item.roleCode === 'CZPHZR') {
                            this.nextRoles.push(item.roleId);
                        }
                    });
                    break;
            }
        },
        //导出表单
        exportFrom() {
            let data = this.dynamicValidateForm;
            fileDownload('/business/operationOrders/download', {method: 'POST', data});
        },
        dataFormSubmit(reviewForm) {
            this.setNextUserAndRole();
            const params = {
                definitionCode: 'elec_operation_ticket',
                operationType: 'saveAndNext',
                nodeCode: this.nextNodeCode,
                instanceId: this.instanceId,
                data: {data: this.dynamicValidateForm, reviewForm},
                nextRoles: this.nextRoles,
                nextUsers: this.nextUsers,
                user: this.$store.state.userInfo.username,
                extraProp: {
                    state: this.nextNodeCode,
                }
            };
            if (params) {
                this.$confirm('确定保存?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.$client.process(params).then((data) => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                        });
                        this.closeForm();
                    });
                });
            }
        },
        closeForm() {
            this.$emit('closeForm');
        },
        init(row) {
            this.instanceId = row.id;
            if (row.nextNode !== null) {
                this.nextNodeCode = row.nextNode.code;
            }
            if (row.curNode.code !== null) {
                this.curCode = row.curNode.code;
            }
            if (row.currentRecord.data && row.currentRecord.data.data.length > 0) {
                this.dynamicValidateForm = row.currentRecord.data.data;
            }
            this.getUser();
        },
        //获取用户信息
        async getUser() {
            const data = await this.$client.getUserInfo();
            let id = data.user.userId;
            let roleData = await this.$client.getUserById(id);
            this.userName = roleData.user.username;
            let res = roleData.user.sysRole;
            for (let i = 0; i < res.length; i++) {
                this.roleInfo.push(res[i].roleCode);
            }
        },
    },
    computed: {
        getDisable() {
            let b = false;
            return function (type) {
                switch (type) {
                    case this.DISABLE_VALUE.FILL:
                        //填写
                        b = this.nextNodeCode !== 'elec_operation_ticket_one';
                        break;
                    case this.DISABLE_VALUE.FILES:
                        //归档
                        b = this.nextNodeCode !== 'elec_operation_ticket_five';
                        break;
                    default:
                }
                return b;
            };
        }
    }
};
</script>
<style>
    .el-textarea .el-input__count {
        background: none;
    }
    .factory_name:focus {
        outline:none;
    }
    .factory_name {
        margin-bottom: 4px;
        width: 160px;
        height: 30px;
        border: none;
        border-bottom: 1px solid #ffffff;
        background-color: transparent;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
    }
</style>
